/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { fonts, mediaQueries, colors } from "../styles"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import SubNav from "../components/SubNav"

const linkStyle = css`
  color: ${colors.deepblue};
`

export default () => {
  return (
    <Layout title="关于清水">
      {/* Container */}
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          ${mediaQueries.phoneLarge} {
            display: block;
            max-width: 100vw;
          }
        `}
      >
        {/* Sidebar */}
        <div
          css={css`
            background-color: rgb(217, 214, 213);
            padding-top: 50vh;
            ${mediaQueries.phoneLarge} {
              padding-top: 300px;
            }
          `}
        >
          <div>
            <SubNav />
            <h1
              css={css`
                font-family: ${fonts.sans};
                text-align: center;
                margin-bottom: 300px;
                ${mediaQueries.phoneLarge} {
                  margin: 0;
                  padding-bottom: 300px;
                }
                &:before {
                  content: "";
                  height: 2rem;
                  width: 2rem;
                  border-radius: 50%;
                  background-color: ${colors.darkgray};
                  position: relative;
                  top: 4px;
                  margin-right: 8px;
                  display: inline-block;
                }
              `}
            >
              关于清水
            </h1>
          </div>
        </div>
        {/* Main Content */}
        <div
          css={css`
            background-color: rgb(42, 42, 42);
            color: rgb(217, 217, 217);
            a {
              color: rgb(217, 217, 217);
            }
          `}
        >
          <ul
            css={css`
              display: flex;
              justify-content: space-around;
              font-size: 1.4rem;
              font-weight: 600;
              margin: 0;
              padding: 1rem 0;
              list-style: none;
              ${mediaQueries.phoneLarge} {
                display: none;
              }

              li {
                position: relative;
              }

              li:before {
                content: "";
                height: 1.4rem;
                width: 1.4rem;
                border-radius: 50%;
                border: 1px solid rgb(217, 217, 217);
                position: relative;
                top: 4px;
                margin-right: 8px;
                display: inline-block;
              }

              li.active:before {
                background-color: rgb(217, 217, 217);
              }

              ul {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                padding-left: 0;
                left: 0;
                top: 21px;
                display: none;
                background: rgb(42, 42, 42);
              }

              li {
                position: relative;
              }

              li:hover > ul,
              li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                box-shadow: 0px 3px 5px -1px rgb(42, 42, 42);
              }

              li ul li {
                clear: both;
                width: 100%;
                text-align: left;
                padding: 10px;
                margin: 0;
                list-style: none;
                transition: all 0.5s ease;
              }

              li ul li:not(:first-of-type) {
                border-top: 1px solid gray;
              }

              li ui li:before {
                border-style: none;
              }

              li ul li:hover {
                transition: all 0.3s ease;
                cursor: pointer;
                color: rgb(1, 9, 90);
                background-color: white;

                &:before {
                  background-color: rgb(1, 9, 90);
                }
              }
            `}
          >
            <li>
              <Link to="/" css={linkStyle}>
                清水艺术
              </Link>
            </li>
            <li className="active">
              <Link to="/about" css={linkStyle}>
                关于清水
              </Link>
            </li>
            <li>
              <Link to="/schools" css={linkStyle}>
                学校介绍
              </Link>
              <ul>
                <li>东京五美大</li>
                <li>关西三美大</li>
                <li>国公立五艺大</li>
                <li>综合类高排名院校</li>
                <li>其他院校</li>
              </ul>
            </li>
            <li>
              课程 -{" "}
              <Link to="/class/normal" css={linkStyle}>
                学部
              </Link>
              ・
              <Link to="/class/master" css={linkStyle}>
                大学院
              </Link>
            </li>
          </ul>
          <div
            css={css`
              padding: 40px 100px;
              font-size: 1.2rem;
              font-weight: 500;
              iframe {
                width: 640px;
                height: 480px;
              }
              ${mediaQueries.phoneLarge} {
                padding: 40px;
                iframe {
                  width: 75vw;
                  height: auto;
                }
                p {
                  font-size: 14px;
                  line-height: 26px;
                }
              }
              p {
                line-height: 33px;
                font-weight: 600;
              }
            `}
          >
            <p>
              清水艺术成立于2018年2月16日，位处于东京上野。
              经过两年的尝试与发展确立了清水艺术的主要基调，致
              力于由艺术学习向社会输出的阶段转变的全体过程。由
              2020年2月起清水艺术迁址至东京秋叶原车站前，作为
              新的起点，期待共同与你共同开辟 Grand Line。
            </p>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.2221355462325!2d139.77288371525924!3d35.69615088019085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ea9ae9748dd%3A0x2f9e82ef6d56c1d6!2z44CSMTAxLTAwMzMgVMWNa3nFjS10bywgQ2hpeW9kYSBDaXR5LCBLYW5kYSBJd2Ftb3RvY2jFjSwgMeKIkjQg5bKp5pys55S644OT44Or!5e0!3m2!1szh-CN!2sjp!4v1579440048792!5m2!1szh-CN!2sjp"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
